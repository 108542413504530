import { Container, ContainerProps } from "@chakra-ui/layout";

const PageContainer: React.FC<ContainerProps> = ({ children, ...rest }) => {
  return (
    <Container
      maxW="container.lg"
      as="section"
      paddingX="12px"
      minHeight="calc(100vh - 65px)"
      paddingTop="1px"
      {...rest}
    >
      {children}
    </Container>
  );
};
export default PageContainer;
