import {
  Box,
  IconButton,
  Avatar,
  Divider,
  Link,
  Tooltip,
} from "@chakra-ui/react";
import { gql, useQuery } from "@apollo/client";
import NextLink from "next/link";

import { SideNavQuery } from "~web/generated/graphql";
import { useAuthContext } from "~web/state/auth";

import ProfileIcon from "./icons/ProfileIcon";
import ConnectedIcon from "./icons/ConnectedIcon";

const SIDE_NAV_QUERY = gql`
  query SideNav {
    userCommunities {
      name
      imageUrl
      urlSlug
    }
  }
`;

const SideNav: React.FC = () => {
  const { data } = useQuery<SideNavQuery>(SIDE_NAV_QUERY);
  const { isAuthed, loginModal } = useAuthContext();

  const communities = data?.userCommunities;

  return (
    <Box
      as="aside"
      display={["none", "none", "flex"]}
      gridColumnStart={1}
      gridColumnEnd={2}
      gridRowStart={1}
      gridRowEnd={3}
      flexDirection="column"
      alignItems="center"
      justifyContent="space-between"
      padding="18px"
      width="64px"
      background="background"
      boxShadow="1px 0px #291e4b1a"
      position="fixed"
      top="0"
      bottom="0"
      overflow="hidden"
      zIndex="999"
    >
      <div>
        {communities?.map((community) => (
          <NextLink
            key={community.urlSlug}
            href={`/community/${community.urlSlug}`}
            passHref
          >
            <Link _focus={{ boxShadow: "none" }}>
              <Tooltip label={community.name} hasArrow placement="right">
                <IconButton
                  aria-label={community.name}
                  size="xs"
                  marginBottom="16px"
                  width="28px"
                  height="28px"
                  borderRadius="full"
                  icon={
                    <Avatar
                      maxWidth="18px"
                      width="28px"
                      height="28px"
                      minW="28px"
                      name={community.name}
                      src={community?.imageUrl || ""}
                    />
                  }
                />
              </Tooltip>
            </Link>
          </NextLink>
        ))}
        <Divider background="gray.400" marginBottom="16px" height="2px" />
      </div>
      <div>
        {isAuthed ? (
          <ConnectedIcon fill="none" height="24px" width="24px" />
        ) : (
          <button onClick={loginModal.show}>
            <ProfileIcon fill="none" height="24px" width="24px" />
          </button>
        )}
      </div>
    </Box>
  );
};

export default SideNav;
