import { Button, Heading, Text } from "@chakra-ui/react";
import { NextPage } from "next";
import NextLink from "next/link";

import AppLayout from "~web/components/AppLayout";
import PageContainer from "~web/components/PageContainer";

const NotFoundPage: NextPage = () => {
  return (
    <AppLayout>
      <PageContainer>
        <Heading>Page not found</Heading>
        <Text my="16px">There&apos;s nothing here.</Text>
        <NextLink href="/" passHref>
          <Button variant="regular">Back to Home</Button>
        </NextLink>
      </PageContainer>
    </AppLayout>
  );
};

export default NotFoundPage;
